import React from 'react';
import { Title, PageSection } from '@patternfly/react-core';
import landingImage from '../../assets/images/27.svg';


function About() {
  return (
    <PageSection>
    <Title headingLevel="h1" size="4xl">
      About OpenShift Almanac
    </Title>
    <img
      src={landingImage}
      alt="Landing"
      className="w-full h-auto"
    />
    <h2 className="text-xl font-bold text-center mb-12">
      We've been working with Red Hat OpenShift Container Platform (RHOCP) on and off for about 4 years supporting various clients, but this year, we've decided to take it seriously and learn the platform soup to nuts. 
      In the process of learning, we fell in love with RHOCP and started to bleed Red. Apart from learning about RHOCP for managing Kubernetes, we're digging deep into learning about Red Hat's approach to 
      infrastructure engineering. OpenShift Almanac is a resource guide that is capturing this learning journey. These resources are what we're using to learn RHOCP, prepare and study for 
      certification exams, and also work on real projects. We plan to document and share trends, updates, and new features on here similar to how farmers reference the annual almanac to make productive farming decisions. 
      Hope you'll stick around, find this useful, and maybe even bleed Red too. - The OpenShift Almanac team.
    </h2>
    <p>NOTE: It is important to note that OpenShift Almanac is not a replacement or even a companion to the Red Hat Learning Subscription. If you want to learn how to use Red Hat OpenShift and other Red Hat products, you must purchase a 
      Red Hat Learning Subscription. Also join the Red Hat Learning Community. OpenShift Almanac is supplemental to Red Hat's required learning resources. 
    </p>
    <p>
      DISCLAIMER: <br />    
      The information provided by Fuwa & Company, LLC. on https://www.openshiftalmanac.com is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.  UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK
      <br /> 
      EXTERNAL LINKS DISCLAIMER
      The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
      PROFESSIONAL DISCLAIMER
      The Site cannot and does not contain professional or other advice. The information is provided for general informational and educational purposes only and is not a substitute for professional advice or the Red Hat Learning Subscription. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals and the Red Hat Learning Subscription. THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THE SITE IS SOLELY AT YOUR OWN RISK


    </p>
  </PageSection>
  );
}

export default About;
