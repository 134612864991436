import React from 'react';
import './ExamTemplate.css';
import {
  PageSection,
  Title,
  Tabs,
  Tab,
  TabTitleText,
  Breadcrumb,
  BreadcrumbItem,
} from '@patternfly/react-core';
import './ExamTemplate.css';
import examPrepImage from '../assets/images/3.svg';

function ExamTemplate({ title, tabs }) {
  const [activeTabKey, setActiveTabKey] = React.useState(0);

  // Toggle currently active tab
  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex);
  };

  return (
      <>
      {/* Header Section */}
      <PageSection variant="dark" className="pf-u-text-align-center">
      <img
          src={examPrepImage}
          alt="Landing"
          width={400} height={400}
          className="left"
        />
        <Title headingLevel="h1" size="4xl">
          {title}
        </Title>
        <p className="pf-u-font-size-lg">
          Review the exam objectives and prepare effectively.
        </p>

      </PageSection>

      {/* Breadcrumbs */}
      <PageSection variant="light">
        <Breadcrumb>
          <BreadcrumbItem to="/">Home</BreadcrumbItem>
          <BreadcrumbItem to="/exams">Exams</BreadcrumbItem>
          <BreadcrumbItem isActive>{title}</BreadcrumbItem>
        </Breadcrumb>
      </PageSection>

      {/* Tabs Section */}
      <PageSection>
        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} isBox>
          {tabs.map((tab, index) => (
            <Tab
              eventKey={index}
              title={<TabTitleText>{tab.title}</TabTitleText>}
              key={index}
            >
              <PageSection>{tab.content}</PageSection>
            </Tab>
          ))}
        </Tabs>
      </PageSection>
      </>
  );
}

export default ExamTemplate;
