import React from 'react';
import { Title, PageSection } from '@patternfly/react-core';

function ARO() {
  return (
    <PageSection>
      <Title headingLevel="h1" size="4xl">
        Tutorials: Azure for Red Hat OpenShift (ARO)
      </Title>
      <p>
        Resources:
        Enable OpenShift ARO in Azure Arc: https://learn.microsoft.com/en-us/answers/questions/1116825/enabling-openshift-aro-cluster-in-azure-arc
        Integrating Azure Arc with ARO: https://cloud.redhat.com/experts/aro/azure-arc-integration/
        Azure Arc with OpenShfit: https://learn.microsoft.com/en-us/training/modules/intro-to-arc-enabled-kubernetes/
      </p>
    </PageSection>
  );
}

export default ARO;