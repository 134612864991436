import React from 'react';
import { Page, PageSection, Title, Tabs, Tab, TabTitleText } from '@patternfly/react-core';
import './TutorialTemplate.css';
import { Breadcrumb, BreadcrumbItem } from '@patternfly/react-core';


function TutorialTemplate({ title, tabs }) {
  const [activeTabKey, setActiveTabKey] = React.useState(0);

  // Toggle currently active tab
  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex);
  };

  return (
    <>
      {/* Header Section */}
      <PageSection variant="dark" className="pf-u-text-align-center">
        <Title headingLevel="h1" size="4xl">
          {title}
        </Title>
        <p className="pf-u-font-size-lg">
          Enhance your OpenShift skills with our comprehensive tutorials.
        </p>
      </PageSection>
     
      <PageSection variant="light">
        <Breadcrumb>
          <BreadcrumbItem to="/">Home</BreadcrumbItem>
          <BreadcrumbItem to="/tutorials">Tutorials</BreadcrumbItem>
          <BreadcrumbItem isActive>{title}</BreadcrumbItem>
        </Breadcrumb>
      </PageSection>

      {/* Tabs Section */}
      <PageSection>
        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} isBox>
          {tabs.map((tab, index) => (
            <Tab
              eventKey={index}
              title={<TabTitleText>{tab.title}</TabTitleText>}
              key={index}
            >
              <PageSection>
                {tab.content}
              </PageSection>
            </Tab>
          ))}
        </Tabs>
      </PageSection>
    </>
  );
}

export default TutorialTemplate;
