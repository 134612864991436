import React from 'react';
import ExamTemplate from '../../components/ExamTemplate';
import { Progress, ProgressMeasureLocation } from '@patternfly/react-core';


function EX280() {
  const tabs = [
    {
      title: 'Manage OpenShift Container Platform',
      content: (
        <div>
          <Progress
            value={10}
            title="Completion"
            measureLocation={ProgressMeasureLocation.top}
          />
          <h2>Grant web console user cluster-admin access to install operator from OperatorHub</h2>
          <p>
            Use the following command to grant cluster-admin access:
          </p>
          <pre>
            <code>oc adm policy add-cluster-role-to-user cluster-admin &lt;user-name&gt;</code>
          </pre>
            <h2>Use the web console to manage and configure an OpenShift cluster</h2>
            <p>

            </p>
            <h2>Use the command-line interface to manage and configure an OpenShift cluster</h2>
            <p>
              login to oc cli, get a list of projects, select a specific project, get console URL
              <pre>
                <code>oc login -u username -p password https://api.clusteraddress.com:6443</code>
                <code>oc projects</code>
                <code>oc project (projectname)</code>
                <code>oc whomai --show-console</code>
              </pre>
            </p>
            <h2>Query, format, and filter attributes of Kubernetes resources</h2>
            <p>
              List nodes, pods, deployments, services, endpoints, routes, scope to a project
              <pre>
                <code>oc get nodes</code>
                <code>oc get pods</code>
                <code></code>
                <code></code>
              </pre>
            </p>
            <h2>Import, export, and configure Kubernetes resources</h2>
            <p>

            </p>
            <h2>Locate and examine container images</h2>
            <p>

            </p>
            <h2>Create and delete projects</h2>
            <p>

            </p>
            <h2>Examine resources and cluster status</h2>
            <p>

            </p>
            <h2>View logs</h2>
            <p>

            </p>
            <h2>Monitor cluster events and alerts</h2>
            <p>

            </p>
            <h2>Assess the health of an OpenShift cluster</h2>
            <p>

            </p>
            <h2>Troubleshoot common container, pod, and cluster events and alerts</h2>
            <p>

            </p>
            <h2>Use product documentation</h2>
            <p>

            </p>
        </div>
      ),
    },
    {
      title: 'Deploy Applications',
      content: (
        <div>
        <Progress
          value={20}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h2>Deploy applications from resource manifests</h2>
          <p>

          </p>
          <h2>Use Kustomize overlays to modify application configurations</h2>
          <p>

          </p>
          <h2>Deploy applications from images, OpenShift templates, and Helm charts</h2>
          <p>

          </p>
          <h2>Deploy jobs to perform one-time tasks</h2>
          <p>

          </p>
          <h2>Manage application deployments</h2>
          <p>

          </p>
          <h2>Work with replica sets</h2>
          <p>

          </p>
          <h2>Work with labels and selectors</h2>
          <p>

          </p>
          <h2>Configure services</h2>
          <p>

          </p>
          <h2>Expose both HTTP and non-HTTP applications to external access</h2>
          <p>

          </p>
          <h2>Work with operators such as MetalLB and Multus</h2>
          <p>

          </p>
        </div>
      ),
    },
    {
      title: 'Manage Storage for Application Configuration and Data',
      content: (
        <div>
        <Progress
          value={30}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
            To configure a service to access a VM on a node or a pod, you must start with a label.
          </p>
          <ul>
            <li>On the VM, the label goes to the <code>spec.template.metadata.labels</code> section.</li>
            <li>You can apply the label to the virt-launcher pod if you don't want to restart the VM; the label applies immediately.</li>
            <li>You then apply the label to the service.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Configure Applications for Reliability',
      content: (
        <div>
        <Progress
          value={40}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h2>Service Types</h2>
          <ul>
            <li><strong>ClusterIP</strong>: For connections within the cluster ONLY.</li>
            <li><strong>NodePort</strong>: For external client access.</li>
            <li><strong>LoadBalancer</strong>: Distributes traffic across multiple servers.</li>
          </ul>
          Configure and use health probes
          Reserve and limit application compute capacity
          Scale applications to meet increased demand
        </div>
      ),
    },
    // Add more tabs for each exam objective
    
    {
      title: 'Manage Application Updates',
      content: (
        <div>
        <Progress
          value={50}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
          Identify images using tags and digests
          Roll back failed deployments
          Manage image streams
          Use triggers to manage images
          </p>
        </div>
      ),
    },
    {
      title: 'Manage Authentication and Authorization',
      content: (
        <div>
        <Progress
          value={60}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
          Configure the HTPasswd identity provider for authentication
          Create and delete users
          Modify user passwords
          Create and manage groups
          Modify user and group permissions
          </p>
        </div>
      ),
    },
    {
      title: 'Configure Network Security',
      content: (
        <div>
        <Progress
          value={70}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
            Configure networking components
            Troubleshoot software defined networking
            Create and edit external routes
            Control cluster network ingress
            Secure external and internal traffic using TLS certificates
            Configure application network policies
            </p>
        </div>
      ),
    },
    {
      title: 'Enable Developer Self-Service',
      content: (
        <div>
        <Progress
          value={80}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
          Configure cluster resource quotas
          Configure project quotas
          Configure project resource requirements
          Configure project limit ranges
          Configure project templates
          </p>
        </div>
      ),
    },
    {
      title: 'Manage OpenShift Operators',
      content: (
        <div>
        <Progress
          value={90}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
           
          Install an operator
          Delete an operator

          </p>
        </div>
      ),
    },
    {
      title: 'Configure Application Security',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
          Configure and manage service accounts
          Run privileged applications
          Create service accounts
          Manage and apply permissions using security context constraints
          Create and apply secrets to manage sensitive information
          Configure application access to Kubernetes APIs
          Configure Kubernetes CronJobs
          </p>
        </div>
      ),
    },
    // ... Add other exam objectives similarly
  ];

  return (
    <ExamTemplate title="OpenShift Virtualization Exam Objectives" tabs={tabs} />
  );
}

export default EX280;
