import React from 'react';
import TutorialTemplate from '../../components/TutorialTemplate';

function Virtualization() {
  const virtComponents = [
    {
      virtComponent : 'kubevirt',
      compFunction : 'Manages kubernetes',
    },
    {
      virtComponent : 'virt-api',
      compFunction : 'Manages VMs and VM related workflows via API.',
    },
    {
      virtComponent : 'virt-launcher',
      compFunction : 'Manages the instantiation of a VMI.',
    },
    {
      virtComponent : 'virt-controller',
      compFunction : 'Manages the lifecyle of the pod hosting the VM.',
    },
    {
      virtComponent : 'Hyper Converge Operator',
      compFunction : 'Manages the CDI and virtualized components for OpenShift Virtualization.',
    },
    {
      virtComponent : 'Qemu Agent',
      compFunction : 'The QEMU (Quick Emulator) guest agent provides system information about the VM it is installed on. ',
    },
    {
      virtComponent : 'KVM',
      compFunction : 'Linux based Virtualization technology that enables guest VMs on host enviroments',
    },
    {
      virtComponent : 'Hostpath Provisioner Operator (HPP)',
      compFunction : 'Provisions storage for VMs',
    },
  ];
  const tabs = [
    {
      title: 'OpenShift Virtualization Overview',
      content: (
        <div>
          <h2>Virtuallization in Red Hat OpenShift</h2>
          {/* Add more content */}
          <p>
            The most important thing to understand about virtualization on OpenShift is that it's not a native feature. 
            Virtualization capability is actually possible via an Operator. The OpenShift Virtualization Operator. Once installed, it uses a HyperConverge Operator to manage Virtual Machines. 
            Due to real life enterpise IT complexities, Red Hat OpenShift make managing Virtual Machines and containerized applications side-by-side a possibility within the same platform.
            This makes it easier for organizations that still rely on virtual machines for mission critical workloads that are also simultaeneously working with containers.
            Even better, Red Hat OpenShift makes some kubernetes features avaialble to managing virtual machines. Services and components such as routes, services, pods, 
            storage (volumes and volume claims), similar cli commands, the unified user interface (UI), yaml definition files, and user management. 
            All of this would be great for greenfield VM projects, but it's even better for brownfield projects because OpenShift Virtualization allows for importing existing VMs from outside the cluster 
            and also live migrating VMs between nodes in the cluster which is extremely valuable to workloads that cannot afford downtime. In other words, on the surface, OpenShift administrators can manage 
            virtual machines with the familiarity of managing containers while OpenShift virtualization handles the translation under the hood.
          </p>
          <h2>OpenShift Virtualization Components</h2>
          <p>Listed and the components that manage virtual machines and thier functions</p>

          <table className="min-w-full bg-white shadow-md rounded my-6">
        <thead>
          <tr>
            <th className="py-3 px-5 bg-gray-200 font-bold uppercase text-sm text-gray-600">Component</th>
            <th className="py-3 px-5 bg-gray-200 font-bold uppercase text-sm text-gray-600">Function</th>
            <th className="py-3 px-5 bg-gray-200"></th>
          </tr>
        </thead>
        <tbody>
          {virtComponents.map((item, index) => (
            <tr key={index} className="border-b">
              <td className="py-4 px-5 text-gray-700">
                {item.virtComponent}
              </td>
              <td className="py-4 px-5 text-gray-700">
                {item.compFunction}
              </td>
              <td className="py-4 px-5 text-gray-700 font-mono">
                <code></code>
              </td>
            </tr>
          ))}
        </tbody>
      </table>  
         
     <h2 id="underlying-kubernetes-architecture">Underlying Kubernetes Architecture</h2>
    
    <hr/>
    <ul>
    <li>Operator: cluster components that simplifies managing another application of function</li>
    <li>Resource: Any of the conifgurable and consumable components managed by Openshift cluster </li>
    <li>Control Plane: Container lifecycle management through API</li>
    <li>Data Plane: Provides storage ,networking and cpu to run nodes in the cluster </li>
    <li>Pod: basic unit of deployment. Runs one or more containers that must be deployed on the same node, share netowrk ip, volumes and other resources </li>
    <li>Container: executable image that defines the libraries and dependencies of an application</li>
    </ul>
    <p>K8s operators: packaged k8s applications </p>
    <h2 id="deploy-openshift-virtualization-operator">Deploy OpenShift Virtualization Operator</h2>
    <hr/>
    <ul>
    <li>How Red Hat OpenShift Virtualization components work<ul>
    <li>Allows users to manage VM and containerized based computing</li>
    <li>Virtualization  supports RHEL 9</li>
    <li>The Operator Lifecycle Manager must be enabled</li>
    </ul>
    </li>
    <li>Deploying Red Hat OpenShift Virtualization Operator using Red Hat OpenShift Container Platform Operator Lifecycle Management</li>
    </ul>
            
    <p>
    <h3 id="routes">Routes</h3>
    <ul>
    <li>Proides http, https, tls traffic to services in the cluster. </li>
    <li>Benefit: automatically assigns a DNS entry to your app when a route is created. </li>
    <li><p>Note format: routename-namespace.default_domain.  If your default domain is apps.mycompany.com, and the route is named itranet and the project is prd? it will be intranet-prod.apps.mycompany.com</p>
    </li>
    <li><p>Data volume uses a PVC under the hood</p>
    </li>
    <li>Data volume is the boot source  </li>
    <li>PVC is a requests for storage </li>
    <li>Virtuam Machine (VM) has the template to instantiate the Virtual machine instance</li>
    <li>Under the hood of the VMI is the pod </li>
    <li>KVM + Libvirt+quemu = Virt launcher pod which runs the virtual machine </li>
    <li>If the virt launcher pod and vmi  are deleted, the vm template can rebuild the vm</li>
    <li>In OpenShift, virtual machines run as pods </li>
    <li><p>The VM templates references the data volume which uses the PVC  the VM image has the boot source. </p>
    </li>
    <li><p>Boot source -&gt; PVC -&gt; Data volume -&gt; VM template -&gt; VMI -&gt; </p>
    </li>
    <li>Virt Launcher Pod -&gt; KVM + lib virtd + qemu </li>
    <li>All pods have this prefix: </li>
    <li><p>kubevirt.io/domain=</p>
    </li>
    <li><p>VMI represents the pod</p>
    </li>
    <li>VM is the template </li>
    <li>VM uses a data volume</li>
    <li><p>Data volume represents a persistent volume claim</p>
    </li>
    <li><p>Pod name starts wuth virt-launcher</p>
    </li>
    <li>Virtual machine is the templates in which the VMI runs </li>
    <li>The VMI runs as a pod</li>
    </ul>
            



OC

role
    -> targets (openshift resources)
      - > verbs (permissions)


Rolebinding is the linker between a user and a group
      <h3 id="cluster-roles">Cluster roles</h3>
      <ul>
      <li>cluster admin<h3 id="namespace-roles-admin-">Namespace roles (admin)</h3>
      </li>
      <li>admin is a namespace role, it&#39;s project specific</li>
      <li>edit: create, modify and remove resources from a project/namespace. You can modify quotas or grant other users permission to your project. </li>
      <li>view: for audit purposes. No create, modify or remove reources from your project </li>
      </ul>
      <h3 id="virt-roles-specific-to-virtualization-resources-">virt roles (specific to virtualization resources)</h3>
      <ul>
      <li>Kubevirt.io:admin </li>
      <li>kubevirt.io:edit</li>
      <li>kubevirt.io:view</li>
      </ul>
      <h3 id="openshift-has-a-built-in-monitoring-framework-based-on-prometheus-it-s-a-datastore-based-on-scraping-">Openshift has a built-in monitoring framework based on prometheus. (It&#39;s a datastore based on scraping)</h3>
      <ul>
      <li>endpoint1</li>
      <li>endpoint2</li>
      <li>endpointn</li>
      <li>Prometheus scrapes the endpoint metrics<ul>
      <li>promQL (used by grafana)</li>
      </ul>
      </li>
      </ul>
      <h3 id="thanos-querier">Thanos querier</h3>
      <ul>
      <li>deduplicates data in Prometheus</li>
      </ul>
      <h3 id="grafana">Grafana</h3>
      <ul>
      <li>a visualization and dashboard tool </li>
      <li>display data relevant to your project </li>
      <li>use PromQL</li>
      </ul>
      <h3 id="alert-manager">Alert manager</h3>
      <ul>
      <li>proactive observability</li>
      <li>based on rules fired when evaluation is true longer than hold threshold. metric is hit</li>
      </ul>
      <h2 id="networking">Networking</h2>
      <ul>
      <li>Node port binding</li>
      </ul>
      <h2 id="route">Route</h2>
      <ul>
      <li>mapping of an fqdn to a service</li>
      </ul>
      <h2 id="edge-termination-">Edge termination:</h2>
      <ul>
      <li><p>TLS terminates at the edge</p>
      </li>
      <li><p>HTTPS between user and router pod</p>
      </li>
      <li><p>HTTP beween router pod and external load balancers</p>
      </li>
      <li><p>TLS is serverd by the router pod</p>
      </li>
      </ul>
      <h3 id="re-encrypted-route-">Re-encrypted route:</h3>
      <ul>
      <li>2 TLS certs:<ul>
      <li>1 cert presented by the routed pod (trusted by the users browser, issued by a trusted CA)</li>
      <li>1 cert by the application pod, issued by an internal CA (internal to the cluster  )</li>
      </ul>
      </li>
      </ul>
      <p>when using oc command use --help to get more details about the command</p>
      <p>Network
      Network from external systems</p>
      <h3 id="storage">Storage</h3>
      <ul>
      <li><p>PVC:</p>
      <ul>
      <li>size</li>
      <li>volumne </li>
      <li>storage class</li>
      <li>access mode (RWO,RWX, ROX)</li>
      </ul>
      </li>
      <li><p>PVC is a namespace resource</p>
      <ul>
      <li>it&#39;s not the actual storage resource, but a storage request</li>
      </ul>
      </li>
      <li><p>StorageClass is a cluster resource</p>
      <ul>
      <li>Volume expansion: True or False</li>
      <li>Bind mode: when storage is created by storage provider</li>
      </ul>
      </li>
      <li>NFS is not supported</li>
      </ul>
      <h3 id="networking-in-openshift">Networking in OpenShift</h3>
      <ul>
      <li>virt admin --&gt; virtctl ssh api --&gt; project -&gt; vm -&gt; vmi -&gt; pod</li>
      </ul>
      <ul>
      <li><p>Services, pods, config maps</p>
      </li>
      <li><p>Resources: pod, vm, vmi, pvc,</p>
      </li>
      </ul>
      <h3 id="templates">Templates</h3>
      <pre><code><span class="hljs-comment">Highly parameterized</span>
      <span class="hljs-comment"> The VM creates a VMI which creates the pod </span>
      <span class="hljs-comment"> Can create the following:</span>
      <ul>
        <li>VM</li>
        <li>Data Volume </li>
        <li>SVC </li>
        <li>Route </li>
        <li>Storage class </li>
        <li>ConfigMap</li>
      </ul>
      </code></pre><ul>
      <li><p>Node Selector and Node Affinity: a preference</p>
      </li>
      <li><p>Taints and Tolerations: must match the prefence to run</p>
      </li>
      <li>Templates are yaml loaded into etcd in openshift</li>
      </ul>

* HA - Probe 
---
Liveness probe
      livenessProbe:
        tcpSocket:
          port: 3306
        initialDelaySeconds: 10
        periodSeconds: 5
</p>
        </div>
      ),
    },
    {
      title: 'OpenShift Virtualization Components',
      content: (
        <div>
          <ul>
            <li>Access to an OpenShift cluster</li>
            <li>Installed OpenShift CLI (`oc`)</li>
            {/* Add more prerequisites */}
          </ul>
          <p>
          Openshift Lfecylce manager
            Operator Hub ; Red Hat | Supported| Comunity |Certified
            Operators are containerized

            Components: 
            Cluster 
            Namespace/Project
          </p>  
          <h2 id="features-of-openshift-virtualization">Features of Openshift Virtualization</h2>
          <hr/>
          <ul>
            <li>Storage management</li>
            <li>Containerized Data Importer (CDI)</li>
            <li>Network management</li>
            <li>Virtual machine consoles</li>
            <li>Workloads management</li>
            <li>Side-by-side and consistent management</li>
            <li>Common infrastructure</li>
            <li>Pipelines for VMs (CI/CD)</li>
            <li>Management with K8s resources</li>
            <li>Standalon VMIs</li>
          </ul>
          <h2 id="virtualization-components-">Virtualization Components:</h2>
          <hr/>
          <ul>
            <li>virt-api</li>
            <li>virt-controller</li>
            <li>virt-hanlder</li>
            <li>virt-launcher</li>
            <li>libvirtd</li>
          </ul>
          <h2 id="openshift-virtualization-operators-and-components-">Openshift virtualization operators and components:</h2>
          <hr/>
          <ul>
            <li>HyperConverged Operator (HCO)</li>
            <li>KubeVirt: KubeVirt is the technology that allows virtual machines function as containers.</li>
            <li>Hostpath Provisioner Operator(HPP)</li>
            <li>Qemu agent</li>
            <li>Kernel-based Virtual Machine (KVM)</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Working with VMs on OpenShift Virtualization',
      content: (
        <div>
          <h2>Step 1: Log in to OpenShift</h2>
          <p>Use the following command to log in:</p>
          <pre>
            <code>oc login --token=YOUR_TOKEN --server=YOUR_SERVER</code>
          </pre>
          {/* Add more steps */}
        </div>
      ),
    },
    {
      title: 'Managing VM Migrations on OpenShift',
      content: (
        <div>
          <p>
            ### Requirements for live migration:
            ---
            * Ports:
              * 49152
              * 49153
          </p>
        </div>
      ),
    },
  ];

  return (
    <TutorialTemplate title="Virtualization on Red Hat OpenShift" tabs={tabs} />
  );
}

export default Virtualization;