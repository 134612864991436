import React from 'react';
import {
  PageSection,
  Title,
  Breadcrumb,
  BreadcrumbItem,
} from '@patternfly/react-core';

function CardTutorialTemplate({ title, children }) {
  return (
    <>
      {/* Header Section */}
      <PageSection variant="dark" className="pf-u-text-align-center">
        <Title headingLevel="h1" size="4xl">
          {title}
        </Title>
        <p className="pf-u-font-size-lg">
          Enhance your OpenShift skills with our comprehensive tutorials.
        </p>
      </PageSection>

      {/* Breadcrumbs */}
      <PageSection variant="light">
        <Breadcrumb>
          <BreadcrumbItem to="/">Home</BreadcrumbItem>
          <BreadcrumbItem to="/tutorials">Tutorials</BreadcrumbItem>
          <BreadcrumbItem isActive>{title}</BreadcrumbItem>
        </Breadcrumb>
      </PageSection>

      {/* Content Section */}
      <PageSection>
        {children}
      </PageSection>
    </>
  );
}

export default CardTutorialTemplate;
