import React from 'react';
import ExamTemplate from '../../components/ExamTemplate';
import { Progress, ProgressMeasureLocation } from '@patternfly/react-core';


function EX380() {
  const tabs = [
    {
      title: 'Configure and manage OpenShift with LDAP and OIDC identity providers',
      content: (
        <div>
          <Progress
            value={10}
            title="Completion"
            measureLocation={ProgressMeasureLocation.top}
          />
          <ol>
          <li>Integrate OpenShift with LDAP</li>
          <li>Integrate OpenShift with Red Hat SSO (Keycloak)</li>
          <li>Manage multiple identity providers</li>
          <li>Configure authentication</li>
          <li>Configure and synchronize OpenShift groups</li>
          <li>Resolve synchronization conflicts</li>
          <li>Maintain group synchronization on a scheduled basis</li>
          <li>Configure RBAC roles with users and groups</li>
          <li>Create and use authentication tokens with kubeconfig files</li>
          <li>Create and use authentication certificates with kubeconfig files</li>
          </ol>
        </div>
      ),
    },
    {
      title: 'Back up and restore applications with OpenShift API for Data Protection (OADP)',
      content: (
        <div>
        <Progress
          value={20}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <li>Export and import a Kubernetes application</li>
          <li>Export and import container images</li>
          <li>Deploy the OADP operator</li>
          <li>Configure a full application backup including data and resources</li>
          <li>Configure and use volume snapshots</li>
          <li>Schedule a recurring backup</li>
          <li>Restore applications</li>
        </div>
      ),
    },
    {
      title: 'Manage workloads with cluster partitioning',
      content: (
        <div>
        <Progress
          value={40}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <ul>
            <li>Dedicate multiple cluster nodes to a specific workload</li>
            <li>Configure and work with node labels and selectors</li>
            <li>Configure and work with machine configurations</li>
            <li>Use the machine configuration operator and special purpose operators</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Manage workloads with pod scheduling',
      content: (
        <div>
        <Progress
          value={60}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h2>Service Types</h2>
          <ul>
          <li>Configure workloads to run on dedicated cluster nodes</li>
          <li>Prevent workloads from running on dedicated cluster nodes</li>
          <li>Configure and work with node taints and tolerations</li>
          <li>Configure and work with project tolerations</li>
          <li>Configure application resiliency using pod disruption budgets</li>
          <li>Configure cluster and project default settings</li>
          <li>Configure and use affinity rules</li>
          </ul>
        </div>
      ),
    },
    // Add more tabs for each exam objective
    {
      title: 'Implement OpenShift GitOps',
      content: (
        <div>
        <Progress
          value={80}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
        <ol>
          <li>Troubleshoot performance and availability issues with applications and clusters</li>
          <li>Configure and silence alerts</li>
        </ol>
        </div>
      ),
    },
    {
      title: 'Provision and inspect cluster logging',
      content: (
        <div>
          <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
        <ol>
          <li>Configure OpenShift logging to forward logs to an external aggregator</li>
          <li>Configure OpenShift logging for short-term log retention and aggregation</li>
          <li>Deploy OpenShift logging with Vector and Loki</li>
          <li>Deploy the Event Router to log Kubernetes events</li>
          <li>Query logs from cluster services and node services</li>
          <li>Diagnose cluster logging problems</li>
        </ol>
        </div>
      ),
    },
    // ... Add other exam objectives similarly
  ];

  return (
    <ExamTemplate title="OpenShift Virtualization Exam Objectives" tabs={tabs} />
  );
}

export default EX380;
