import React from 'react';
import { Title, PageSection } from '@patternfly/react-core';
import { Link } from 'react-router-dom';

function Tutorials() {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="bg-red-700 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">
            Red Hat OpenShift Container Platform Tutorials
          </h1>
          <p className="text-xl mb-8">
            Tutorials for RHOCP and Related Technologies
          </p>
          <Link
            to="/"
            className="bg-white text-red-700 font-semibold py-2 px-4 rounded"
          >
            Explore Updates
          </Link>
        </div>
      </div>

      {/* Features Section */}
      <div className="container mx-auto py-16">
        <h2 className="text-3xl font-bold text-center mb-12">
          What You'll Find
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Feature Item */}
          <div className="bg-white p-6 rounded shadow">
          <Link
            to="/tutorials/openshift"
            >
            <h3 className="text-xl font-bold mb-2">Red Hat OpenShift Container Platform (RHOCP)</h3>
            <p>
              Stay up-to-date with the latest releases and features of OpenShift.
            </p>
            </Link>
          </div>
          {/* Repeat for other features */}
          <div className="bg-white p-6 rounded shadow">
            <Link
              to="/tutorials/linux"
            >
            <h3 className="text-xl font-bold mb-2">Red Hat Enterprise Linux (RHEL)</h3>
            <p>
              Step-by-step guides to help you master OpenShift.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
          <Link
            to="/tutorials/kubernetes"
            >
            <h3 className="text-xl font-bold mb-2">Kubernetes</h3>
            <p>
              Quick access to common commands for podman, oc, virtctl, and more.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
              to="/tutorials/podman"
            >
            <h3 className="text-xl font-bold mb-2">Podman</h3>
            <p>
              Learn about the latest in container technology.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
              to="/tutorials/virtualization"
            >
            <h3 className="text-xl font-bold mb-2">OpenShift Virtualization</h3>
            <p>
              Learn OpenShift virtualization capabilities.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
              to="/tutorials/ansible"
            >
            <h3 className="text-xl font-bold mb-2">Ansible</h3>
            <p>
              Quick access to common commands for podman, oc, virtctl, and more.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
              to="/tutorials/rosa"
            >
            <h3 className="text-xl font-bold mb-2">Red Hat OpenShfit on AWS (ROSA)</h3>
            <p>
              Prepare for exams.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
              to="/tutorials/aro"
            >
            <h3 className="text-xl font-bold mb-2">Azure for Red Hat OpenShift (ARO)</h3>
            <p>
              Learn OpenShift virtualization capabilities.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
              to="/tutorials/RHOAI"
            >
            <h3 className="text-xl font-bold mb-2">Red Hat OpenShift AI (RHOAI)</h3>
            <p>
              Quick access to common commands for podman, oc, virtctl, and more.
            </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tutorials;