import React, { useState } from 'react';
import {
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  CardExpandableContent,
  ExpandableSection,
} from '@patternfly/react-core';
import { AngleRightIcon, AngleDownIcon } from '@patternfly/react-icons';

function TopicCard({ title, summary, content }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card isHoverable isCompact>
      <CardHeader onClick={handleToggle} className="cursor-pointer">
        <CardTitle>{title}</CardTitle>
        <div className="pf-c-card__header-toggle">
          {isExpanded ? <AngleDownIcon /> : <AngleRightIcon />}
        </div>
      </CardHeader>
      <CardBody>
        {!isExpanded && <p>{summary}</p>}
        {isExpanded && (
          <ExpandableSection isExpanded>
            {content}
          </ExpandableSection>
        )}
      </CardBody>
    </Card>
  );
}

export default TopicCard;
