import React from 'react';
import CardTutorialTemplate from '../../components/CardTutorialTemplate';
import TopicCard from '../../components/TopicCard';
import { Grid, GridItem } from '@patternfly/react-core';

function OpenShift() {
  const apiResources = [
    // ... (your existing apiResources array)
  ];

  const topics = [
    {
      title: 'Deploying Applications',
      summary: '',
      content: '',
    },
    {
      title: 'Managing Storage for Application Data',
      summary: '',
      content: '',
    },
    {
      title: 'Managing Operators',
      summary: '',
      content: '',
    },
    {
      title: 'Configure Network Security',
      summary: '',
      content: '',
    },
    {
      title: 'Concepts and Architecture',
      summary: '',
      content: '',
    },
    {
      title: '',
      summary: '',
      content: '',
    },
    {
      title: '',
      summary: '',
      content: '',
    },
    {
      title: 'OpenShift Container Platform Overview (Enterprise Kubernetes)',
      content: (
        <>
          <p>Welcome to the tutorial on deploying applications in OpenShift.</p>
          <h2>OpenShift Resources</h2>
          {/* Render the API resources table */}
          <table className="pf-c-table pf-m-grid-md">
            <thead>
              <tr>
                <th>Resource Name</th>
                <th>Short Name</th>
                <th>Function</th>
              </tr>
            </thead>
            <tbody>
              {apiResources.map((item, index) => (
                <tr key={index}>
                  <td>
                    <code>{item.resource}</code>
                  </td>
                  <td>{item.shortname}</td>
                  <td>{item.function}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ),
    },
    {
      title: 'Red Hat OpenShift Virtualization (VMs)',
      content: (
        <>
          <p>Visit the Virtualization page</p>
          <ul>
            <li>Access to an OpenShift cluster</li>
            <li>Installed OpenShift CLI (`oc`)</li>
            {/* Add more prerequisites */}
          </ul>
        </>
      ),
    },
    {
      title: 'Red Hat OpenShift Service on AWS (ROSA)',
      content: (
        <>
          <ol>
          <li>Deploy applications from external registries</li>
            <li>Configure ROSA projects with Quay.io</li>
            <li>Configure ROSA projects with Amazon Elastic Container Registry</li>
          <li>Provide Amazon storage volumes for applications</li>
            <li>Configure storage classes for different Elastic Block Storage volume types.</li>
            <li>Configure ROSA to use Elastic Filesystem Storage shares</li>
          <li>Configure application access to AWS services</li>
            <li>Understand IAM Roles for Service Accounts (IRSA)</li>
            <li>Provision AWS infrastructure and services using AWS Controllers for Kubernetes</li>
            <li>Configure observability for OpenShift on AWS</li>
          <li>Configure structured application logging</li>
          <li>Query application logs from Cloudwatch</li>
          <li>Configure user workload monitoring with AWS Managed Service for Prometheus</li>
          <li>Configure node and pod autoscaling<ol>
            <li>Create node pools</li>
            <li>Schedule pods to node pools</li>
            <li>Configure workload autoscaling</li>
            <li>Configure cluster node autoscaling</li></ol></li>
          {/* Add more steps */}
          </ol>
        </>
      ),
    },
    {
      title: 'Microsoft Azure Red Hat OpenShift (ARO)',
      content: (
        <>
        <ol>
          <li>Provision a Managed Azure Red Hat OpenShift Cluster</li>
          <li>Create a Microsoft Azure Red Hat OpenShift cluster accessible through the internet.</li>
          <li>Introduction to Managed OpenShift Clusters</li>
          <li>Describe the relationship between the customer team and the cloud vendors SRE team regarding system administration of managed OpenShift clusters.</li>
          <li>Prerequisites to Create an Microsoft Azure Red Hat OpenShift Cluster</li>
          <li>Learn the different options to deploy Microsoft Azure Red Hat OpenShift clusters in public or private mode and prepare an Azure cloud account and a management workstation to create an Microsoft Azure Red Hat OpenShift cluster.</li>
          <li>Create an Microsoft Azure Red Hat OpenShift Cluster</li>
          <li>Create an internet-accessible managed OpenShift cluster using the Azure portal and inspect the cloud resources that compose an Microsoft Azure Red Hat OpenShift cluster.</li>
          <li>Access an Microsoft Azure Red Hat OpenShift Cluster as an Administrator</li>
          <li>Retrieve OpenShift cluster administrator credentials to access a managed cluster.</li>
          <li>Configure a Managed Azure Red Hat OpenShift Cluster</li>
          <li>Configure a Microsoft Azure Red Hat OpenShift cluster to be used for development purposes.</li>
          <li>Configure Developer Self-service Access to an Microsoft Azure Red Hat OpenShift Cluster</li>
          <li>Configure an identity provider that allows developers to access a managed cluster and create self-service projects to deploy unprivileged applications.</li>
          <li>Connect an Microsoft Azure Red Hat OpenShift cluster to Red Hat cloud services</li>
          <li>Connect a managed cluster to Red Hat cloud services and enumerate the benefits.</li>
          <li>Configure Additional Storage Classes</li>
          <li>Connect applications to the Azure disk type that matches their cost and performance requirements.</li>
          <li>Create Dedicated Node Pools</li>
          <li>Add a machine set to run applications that use different VM sizes.</li>
          <li>Configure Node Autoscaling</li>
          <li>Autoscale a node pool according to application load.</li>
          <li>Configure Log Forwarding</li>
          <li>Forward cluster and pod logs to Azure Monitor.</li>
          </ol>
        </>
      ),
    },
    {
      title: 'Red Hat OpenShift AI',
      content: (
        <>
          <p>Coming soon! We've gotta learn it first.</p>
          {/* Add more content */}
        </>
      ),
    },
    {
      title: 'Authentication',
      summary: 'Authenticating into OpenShift',
      content: (
        <>
          <ol>
            <li>Authentication in Kubernetes requires one of 2 types of authentication verification: a token or a client certificate.</li>
            <li>The API server is the control plane component responsible for API incoming requests</li>
            <li>If the user doesn't present a token or a client certificate, the user is assigned system:anonymous system user and system:unauthenticated system group.</li>
            <li>OpenShift Shift uses an OAuth Server (on a cluster authentication operator) to handle authentication. It issues OAuth tokens for access to specific resources.</li>
            <li>You can authenticate to the API server via X.509 client certificates or OAuth access tokens</li>
            <li>OpenShift OAuth Servers only work with supported Identity Providers (IdP). Unsupported IdPs cannot be added.</li>
            <li>Users, Groups, and Identities are how users gain access to the cluster</li>
            <li>System Groups or virtual groups are predefined in the cluster as system:authenticated, system:unauthenticated:oauth, system:unauthenticted, system:masters</li>
            <li></li>
          </ol>
        </>
      )
    },
  ];

  return (
    <CardTutorialTemplate title="OpenShift Tutorials">
            <Grid hasGutter>
        {topics.map((topic, index) => (
          <GridItem key={index} span={12} md={6} lg={4}>
            <TopicCard
              title={topic.title}
              summary={topic.summary}
              content={topic.content}
            />
          </GridItem>
        ))}
      </Grid>
    </CardTutorialTemplate>
  );
}

export default OpenShift;
