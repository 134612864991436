import React from 'react';
import { Link } from 'react-router-dom';
import refGuideImage from '../../assets/images/1.svg';
import tutorialsImage from '../../assets/images/2.svg';
import examPrepImage from '../../assets/images/3.svg';
import compImage from '../../assets/images/14.svg';
import virtImage from '../../assets/images/23.svg';

function Linux() {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="bg-red-700 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">
            Red Hat Enterprise Linux
          </h1>
          <p className="text-xl mb-8">
            Your comprehensive overview of Red Hat Enterprise Linux
          </p>
          <Link
            to="/updates"
            className="bg-white text-red-700 font-semibold py-2 px-4 rounded"
          >
            Explore Updates
          </Link>
        </div>
      </div>

      {/* Features Section */}
      <div className="container mx-auto py-16">
        <h2 className="text-3xl font-bold text-center mb-12">
          What You'll Find
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Feature Item */}
          <div className="bg-white p-6 rounded shadow">
          <Link
            to="/updates"
            >
            <img
              src={compImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">Understanding Essential Linux Tools</h3>
            <p>
              Stay up-to-date with the latest releases and features of OpenShift.
            </p>
            </Link>
          </div>
          {/* Repeat for other features */}
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/tutorials"
            >            
            <img
              src={tutorialsImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">Operating Running Systems</h3>
            <p>
              Step-by-step guides to help you master OpenShift.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/cli-guides"
            >
            <img
              src={refGuideImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">Deploying, Configuring, and Maintaining Systems</h3>
            <p>
              Schedule tasks using at and cron
              at 

              cron
                Field in a crontab file
                * * * * * command
                minutes
                hours
                day of the month
                month
                day of the week  (0-7, where 0,7 equals Sunday)
              Start and stop services and configure services to start automatically at boot
              Configure systems to boot into a specific target automatically
              Configure time service clients
              Install and update software packages from Red Hat Network, a remote repository, or from the local file system
              Modify the system bootloader
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/exams"
            >
            <img
              src={examPrepImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">Configuring Storage</h3>
            <p>
              Prepare for exams with the same prep notes we use.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/tutorials/virtualization"
            >
            <img
              src={virtImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">Networking</h3>
            <p>
              Learn OpenShift virtualization capabilities.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
           <Link
            to="/tutorials/linux"
            >
            <img
              src={refGuideImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">Creating and Configuring File Systems</h3>
            <p>
              Primers on Red Hat Enterprise Linux, Ansible, and more.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
          <Link
            to="/updates"
            >
            <img
              src={compImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">Managing Users and Groups</h3>
            <p>
              Stay up-to-date with the latest releases and features of OpenShift.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
          <Link
            to="/updates"
            >
            <img
              src={compImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">Managing Security</h3>
            <p>
              Stay up-to-date with the latest releases and features of OpenShift.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
          <Link
            to="/updates"
            >
            <img
              src={compImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">Managing Containers</h3>
            <p>
              Stay up-to-date with the latest releases and features of OpenShift.
            </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Linux;